/* dichvu.css */
.form-container {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-container h3 {
  margin-top: 20px;
  color: #555;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #444;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.server-list {
  list-style: none;
  padding: 0;
}

.server-list li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.server-list li:hover {
  background-color: #f9f9f9;
}

.server-description {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  background: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
}
.badge-success{
  color: #fff;

  background-color: #28a745;

}
.badge-primary{
  color: #fff;

  background-color: #5671f0;

}
.badge-danger {
  color: #fff;
  background-color: #f35d5d;
}
.badge {
  display: inline-block;
  /* background-color: #28a745; */
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
}

.total-cost {
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.submit-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.orders-table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th,
.orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.orders-table th {
  background-color: #f2f2f2;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* đảm bảo nằm trên cùng */
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
