 .smmdv-container {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  
  .smmdv-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .smmdv-form {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .smmdv-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .smmdv-form input,
  .smmdv-form select,
  .smmdv-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .smmdv-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .smmdv-form button:hover {
    background-color: #0056b3;
  }
  
  .server-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .server-table th,
  .server-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .server-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .server-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .server-table button {
    margin-right: 5px;
    padding: 5px 10px;
    font-size: 0.9rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .server-table button:first-of-type {
    background-color: #ffc107;
    color: #fff;
  }
  
  .server-table button:last-of-type {
    background-color: #dc3545;
    color: #fff;
  }
  
  .server-table button:hover {
    opacity: 0.8;
  }
  

.smm-form-container {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .smm-form-title,
  .smm-list-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .smm-form {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
   */
  .submit-btn {
    display: block;
    width: 100%;
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  .rsp-table{
    overflow-x: auto;

  }
  .smm-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .smm-table th,
  .smm-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .smm-table th {
    background-color: #f4f4f4;
  }
  
  .smm-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-btn:hover {
    background-color: #c82333;
  }
  