/* AddBalanceForm.css */
.add-balance-form-container {
    border: 1px solid #000;
    padding: 10px;
    margin-top: 10px;
    max-width: 400px;
    background-color: #f9f9f9;
  }
  
  .add-balance-form-container h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
  }
  
  .add-balance-form-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .add-balance-form-container input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .add-balance-form-container button {
    padding: 8px 12px;
    margin-right: 10px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-balance-form-container button[type="button"] {
    background-color: #dc3545;
  }
  
  .add-balance-form-container button:hover {
    opacity: 0.9;
  }
  