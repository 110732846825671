
.header .title a{
  color: #fff;
}
  .illustration {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .illustration img {
    max-width: 100%;
    height: auto;
  }
  
  .login-form {
    margin: 0 auto;
    flex: 1;
    max-width: 400px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form h2 {
    color: #1a73e8;
    margin-bottom: 20px;
  }
  
  .login-form .error-message {
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  a{
    text-decoration: none;
  }
  .login-form .input-group {
    margin-bottom: 15px;
    position: relative;
  }
  
  .login-form .input-group input {
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-form .input-group .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
  }
  
  .login-form .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .login-form .options label {
    display: flex;
    align-items: center;
  }
  
  .login-form .options input {
    margin-right: 5px;
  }
  
  .login-form .options a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .login-form .login-button {
    width: 100%;
    padding: 10px;
    background-color: #1a73e8;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-form .divider {
    text-align: center;
    margin: 20px 0;
    color: #888;
  }
  
  .login-form .create-account-button {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    color: #1a73e8;
    border: 1px solid #1a73e8;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .login-form .create-account-button a{
    text-decoration: none;
  }


  .header {
    background-color: #38414a;
    color: white;
    height: 70px;
    display: flex;

    justify-content: space-between;
    align-items: center;
  }
  .header .title {
    margin-left: 10px;
    font-size: 22px;
    display: flex;
  }
  .header .menu-icon {
    font-size: 24px;
    cursor: pointer;
    margin-left: 20px;
  }
  .header .user-info {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .header .user-info img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
  /* .header .user-info .dark-mode-toggle {
    margin-right: 20px;
  } */
  
.container {
  display: flex;
  padding: 20px;
}
.sidebar {
  width: 20%;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.sidebar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 10px;
}
.sidebar .name {
  text-align: center;
  font-weight: 500;
  margin-bottom: 5px;
}
.sidebar .rank {
  text-align: center;
  color: gray;
  margin-bottom: 20px;
}
.sidebar .menu {
  list-style: none;
  padding: 0;
}
.sidebar .menu li {
  margin-bottom: 10px;
}
.sidebar .menu li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}
.sidebar .menu li a:hover {
  color: #007bff;
}
.sidebar .menu li a.vip {
  color: red;
}
.content {
  display: flex;
  width: 80%;
  /* padding: 0 20px; */
}
.content .section {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.content .section h2 {
  margin-top: 0;
}
.content .section p {
  margin: 10px 0;
}
.content .section a {
  color: #007bff;
  text-decoration: none;
}
.content .section a:hover {
  text-decoration: underline;
}
.content .section .notification {
  margin-bottom: 10px;
}
.content .section .notification p {
  margin: 5px 0;
}
/* Cấu trúc chung */
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 20%;
  margin: 0 auto;
}

.profile img {
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile .name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.profile .rank {
  font-size: 16px;
  color: #777;
  margin-bottom: 15px;
}

.profile .info {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  width: 100%;
}

.profile .info li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
}

.profile .info li span {
  font-weight: bold;
  color: #333;
}

.profile .actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.profile .actions button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile .actions button:hover {
  background-color: #45a049;
}

.profile .actions button:active {
  background-color: #3e8e41;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.orders-table th, .orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.orders-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.orders-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.orders-table tr:hover {
  background-color: #ddd;
}


@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .content, .profile {
    width: 100%;
    margin-bottom: 20px;
  }
}
.content {
  flex: 1;
  background-color: white;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.content h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.content .form-group {
  margin-bottom: 20px;
}

.content .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.content .form-group input,
.content .form-group textarea {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.content .form-group .note {
  color: red;
  font-size: 14px;
}

.content .form-group .note a {
  color: red;
  text-decoration: none;
}

.content .server-list {
  list-style: none;
  padding: 0;
}

.content .server-list li {
  margin: 10px 0;
}

.content .server-list li label {
  display: flex;
  align-items: center;
}

.content .server-list li input {
  margin-right: 10px;
}

.content .server-list li .badge {
  background-color: #28a745;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 10px;
}

.total-cost {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.message {
  margin-top: 20px;
  color: red;
  font-size: 16px;
}

.orders-table-container {
  width: 100%;
  overflow-x: auto; /* Bật thanh cuộn ngang khi cần */
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* Đảm bảo bảng không bị quá nhỏ */
}

.orders-table th, .orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  /* white-space: nowrap; Giữ nội dung trên cùng một dòng, không tự động xuống dòng */
}

.orders-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
